import React, { useState, useEffect } from "react";
import Table from './Table';
import ReportAcordeon from '../../../Components/ReportAcordeon'
import { 
  listUsersAccess
} from "../../../../../services/NexiDigitalAPI";
import Filtro from "./Filtro";


const UsersAccess = ({ index, expanded }) => {
    const name = "Lista de usuários com acesso de conteúdo"
    const description = "Filtra usuários pelo conteúdo acessado.";

    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);

    const actionFilter = async (values) => {

      setLoading(true);
      // Sessions
      const filterValues = {
        startDate: values.startDate,
        endDate: values.endDate,
        idContentItem: values.idContentItem 
      }
      const responseData = await listUsersAccess(filterValues);
      setDados(responseData);
 
      setLoading(false);
  }

    // useEffect(()=>{
    //     getData();
    // },[])

    
    const filter = (
      <Filtro 
          actionFilter={actionFilter} 
          loading={loading} 
      />
  );

    const result = (
        <Table 
            dados={ dados } 
            loading={ loading }
        />
    );
    
    return (
        <ReportAcordeon
            name={name}
            description={description}
            filter={filter}
            result={result}
            index={ index }
            expanded={expanded}
        />
        
    )
}

export default UsersAccess;